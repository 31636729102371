import React, { useState, useEffect } from "react";
import { Grommet, Grid, Box, Paragraph, Text, Chart, Select } from "grommet";
import axios from "axios";
import { getColour, getFromLocalStorage, getSymbol, saveToLocalStorage } from "./common";
import profiles from './profiles.json';

const theme = {
  global: {
    font: {
      family: "Roboto",
      size: "18px",
      height: "20px",
    },
    colors: {
      background: "linear-gradient(102.77deg, #865ED6 -9.18%, #18BAB9 209.09%)"
    },
  },
  select: {
    background: "transparent"
  }
};

const currentProfileKey = "currentProfile";

const App = () => {
  const savedProfile = getFromLocalStorage(currentProfileKey);
  const currentProfile = profiles.find(x => x.name === savedProfile) || profiles[0];
  const [selectedProfile, setProfile] = useState(currentProfile.name);

  const [currentPrice, setCurrentPrice] = useState({});
  const [history, setHistory] = useState([]);

  useEffect(() => {
    async function getStats() {
      try {
        const response = await axios(`https://api.coindesk.com/v1/bpi/currentprice/aud.json`);
        const data = response.data;
        setCurrentPrice(data);
      } catch (exception) { }
    }
    getStats();
  }, []);

  useEffect(() => {
    async function getHistory() {
      try {
        const response = await axios(`https://api.coindesk.com/v1/bpi/historical/close.json`);
        const data = response.data;
        if (data.bpi) {
          setHistory(
            Object.values(data.bpi).map((x, i) => {
              return { value: [i, x] };
            })
          );
        }
      } catch (exception) { }
    }
    getHistory();
  }, []);

  const handleProfileChange = (option) => {
    setProfile(option);
    saveToLocalStorage(currentProfileKey, option);
  }

  let totalUnits = 0;
  let totalAverages = 0;
  for (const transaction of currentProfile.transactions) {
    totalUnits += transaction.units;
    totalAverages += transaction.price * transaction.units;
  }

  const weightedAverage = totalAverages / totalUnits; // Average buy in price
  const initialValue = totalUnits * weightedAverage; // Average buy in value

  const rate = currentPrice?.bpi?.AUD?.rate_float || 0;
  const valueChange = rate ? rate * totalUnits - totalUnits * weightedAverage : 0;
  const valueChangePercent = valueChange / initialValue * 100;

  return (
    <Grommet theme={theme} full>
      <Grid
        rows={['xxsmall', 'flex', 'xsmall']}
        columns={['flex']}
        areas={[['header'], ['main'], ['footer']]}
        fill
      >
        <Box gridArea="header" direction="row">
          <Select
            options={profiles.map(x => x.name)}
            value={selectedProfile}
            onChange={({ option }) => handleProfileChange(option)}
            style={{ width: "48px", background: "transparent" }}
            plain={true}
          />
        </Box>
        <Box gridArea="main" direction="row" align="center" justify="center" fill>
          <Box align="center" animation={{ type: "fadeIn", duration: 1000 }}>
            <Text color="white">Current Value</Text>
            <Text color="white" size="48px">
              ${(rate * totalUnits).toLocaleString()}
            </Text>
            <Text color={getColour(valueChange)}>
              ${valueChange.toLocaleString()} ({getSymbol(valueChangePercent)}
              {valueChangePercent.toLocaleString()}%)
          </Text>
            <Paragraph color="white" margin="large" textAlign="center">
              You own {totalUnits.toLocaleString()} BTC or {(totalUnits * 1000).toLocaleString()} mBTC
              <br />
              1 BTC is ${currentPrice?.bpi?.AUD?.rate || 0}
            </Paragraph>
          </Box>
        </Box>
        <Box gridArea="footer" direction="row" justify="center" overflow="hidden">
          <Chart type="line" thickness="2" opacity="medium" values={history} animate />
        </Box>
      </Grid>
    </Grommet>
  );
};

export default App;
