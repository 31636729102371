export const getColour = (value) => {
  if (value === 0) {
    return "white";
  }
  if (value > 0) {
    return "status-ok";
  }
  return "status-error";
};

export const getSymbol = (value) => {
  if (value === 0) {
    return "";
  }
  if (value > 0) {
    return "+";
  }
  return "-";
};

export const saveToLocalStorage = (key, value) => {
  try {
    return localStorage.setItem(key, value);
  } catch (exception) {
  }
}

export const getFromLocalStorage = (key) => {
  try {
    return localStorage.getItem(key);
  } catch (exception) {
  }
}